<template>
    <div>
        <div class="paid-area">
            <ul class="paid-area-show">
                <li>
                    <span>应收</span>
                    <span class="price">{{ totalPrice }}</span>
                </li>
                <li>
                    <span>实收</span>
                    <span class="price">{{ manualcheckoutPrice ? manualcheckoutPrice : 0 }}</span>
                </li>
                <li>
                    <span>找零</span>
                    <span class="price">{{ manualcheckoutPrice - totalPrice }}</span>
                </li>
            </ul>
            <div class="paid-area-action">
                <span @click="getVal('1')">1</span>
                <span @click="getVal('2')">2</span>
                <span @click="getVal('3')">3</span>
                <span @click="getVal('4')">4</span>
                <span @click="getVal('5')">5</span>
                <span @click="getVal('6')">6</span>
                <span @click="getVal('7')">7</span>
                <span @click="getVal('8')">8</span>
                <span @click="getVal('9')">9</span>
                <span @click="getVal('0')">0</span>
                <span @click="getVal('00')">00</span>
                <span @click="getVal('.')">.</span>
                <span @click="getVal('20')">¥20</span>
                <span @click="getVal('10')">¥10</span>
                <span @click="getVal('50')">¥50</span>
                <span @click="getVal('100')">¥100</span>
                <span @click="delLast">删除</span>
                <span @click="clear">清空</span>
                <span @click="">结算</span>
                <!-- <span @click="submit">现金</span> -->
            </div>
            <div class="best-wish">智付，支付不迷路！</div>
        </div>
    </div>
</template>

 <script>

    export default {
        data() {
            return {
                manualcheckout: [],
                manualcheckoutPrice: 0,
                totalPrice: 0,
            }
        },
        mounted() {

        },
        methods: {

            //清空金额
            clear() {
                this.manualcheckout = [];
                this.manualcheckoutPrice = 0;
            },
            // 删除最后一位数字
            delLast() {
                this.manualcheckout.pop();
                this.manualcheckoutPrice = this.manualcheckout.join('');
                console.log(this.manualcheckoutPrice);
            },
            // 获取键盘输入的值并且拼接
            getVal(val) {
                this.manualcheckout.push(val);
                this.manualcheckoutPrice = this.manualcheckout.join('');
                console.log(this.manualcheckoutPrice);
            },
        },
    }


</script>

    <style></style>