<template>
  <div class="head-box" v-if="shopInfo">
    <div class="userInfo">
      <div class="shop">
        <el-select v-model="selectedShopId" @change="setShopName" :disabled="isShow">
          <el-option :label="item.name" :value="item.id" v-for="(item, index) in shopInfoList" :key="index"
            class="elOption"></el-option>
        </el-select>
      </div>
      <div>{{userInfo.realname}}</div>
<!--      <el-avatar :size="50" :src="avatar" fit="fit" class="avatar"></el-avatar>-->
      <el-button type="danger" @click="logout" class="logout">退出</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      avatar: '',
      selectedShopId: null
    };
  },
  computed: {
    ...mapState({
      shopInfoList: state => state.shopInfoList,
      shopInfo: state => state.shopInfo,
      userInfo: state => state.userInfo
    }),
    isShow() {
      return this.shopInfoList.length < 2
    },
  },
  watch: {
    shopInfo: {
      handler(newShopInfo) {
        if (newShopInfo && newShopInfo.id) {
          this.selectedShopId = newShopInfo.id;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getAvatar();
  },
  methods: {
    ...mapActions(["setShopInfo"]),
    ...mapMutations(["updateShopInfo"]),
    setShopName(id) {
      console.log('Selected shop ID:', id);
      this.$http.get(`/shopUser/setRecentShop?shopId=${id}&type=setShop`).then(res => {
        if (res.success) {
          this.$message.success("切换店铺成功");
          const selectedShop = this.shopInfoList.find(shop => shop.id === id);
          if (selectedShop) {
            this.updateShopInfo(selectedShop);
            this.setShopInfo(selectedShop);
          }
          this.$emit("clearGoods");
        } else {
          this.$message.error(res.message);
        }
      }).catch(error => {
        console.error('Error:', error);
      });
    },
    getAvatar() {
      if (this.userInfo && this.userInfo.avatar) {
        // this.avatar = `http://110.41.59.198:8080/liwen-erp/sys/common/static/${this.userInfo.avatar}`;
        this.avatar = process.env.VUE_APP_IMG_URL + this.userInfo.avatar;
      }
    },
    logout() {
      this.$emit("logout", this.shopInfo.id);
    }
  }
};
</script>

<style scoped>
.head-box {
  opacity: 0.9;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.userInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  /* 设置水平间距为15px */
}

.shop {
  flex: 1;
  /** 剩余空间分配给店铺选择框 */
  margin-top: -10px;

}

.el-select {
  width: 200px;
  display: flex;
  justify-content: center;
}

.avatar {
  margin-top: -15px;
}

.logout {
  margin-top: -10px;
  margin-left: 20px;
  /**-webkit-clip-path: polygon(44% 34%, 86% 43%, 82% 88%, 14% 89%, 3% 46%);
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  clip-path: polygon(44% 34%, 86% 43%, 82% 88%, 14% 89%, 3% 46%);**/
}

.logout:hover {
  margin-top: -16px;
  background-color: #ff4d4f;
  color: #fff;
}

.elOption {
  border-top: 1px solid #2f8be7;
  border-bottom: 1px solid #2f8be7;
  width: 98%;
}
</style>
