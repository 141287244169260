<template>
  <div class="main_box" :style="{ height: screenHeight + 'px' }">
    <div class="shouyin-header2">
      <h1>昆明树木园商品扫码入库</h1>
      <!-- <Logout @logout="logout" @clearGoods="clearGoods"></Logout> -->
    </div>
    <div class="shouyin-main" id="app" v-loading="loading">
      <div class="scan-area">
        <div class="scan-form">
          <input
            @blur="blur"
            type="text"
            class="input-box"
            readonly="true"
            autofocus
            v-model="goodsCode"
            :placeholder="inputMsg"
            @keydown="showGoods"
            ref="inputRef"
          />
        </div>
        <div class="scan-list">
          <div class="scan-list-content">
            <table cellspacing="0">
              <thead>
                <tr>
                  <th>图片</th>
                  <th>品名</th>
                  <th>数量</th>
                  <th>成本(单价)</th>
                  <th>入库小计</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(good, index) in goods" :key="index">
                  <td>
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="good.thumb"
                      fit="fit"
                    ></el-image>
                  </td>
                  <td>{{ good.name }}</td>
                  <td>
                    <div style="display: flex;align-items: center;justify-content: center;line-height:30px">
                      <button @click="reduce(index)">-</button>
                      <div
                        @click="editnum(index,good.num,good.salePrice)"
                        class="custominput"
                      >
                        {{ good.num }}
                      </div>
                      <button @click="addition(index)">+</button>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex;align-items: center;justify-content: center;line-height:30px">
                      <div
                        @click="editnum(index,good.num,good.salePrice)"
                        class="custominput"
                      >
                      {{ good.salePrice }}
                      </div>
                    </div>
                  </td>
                  <th>{{ (good.salePrice * good.num).toFixed(2) }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="scan-list-action">
            <div class="btn-box">
              <input type="button" value="提交入库" @click="submitreceiving" />
              <input type="button" value="首页" @click="goodsHome" />
            </div>
            <div class="other-list">
              <div class="other-list-time">
                <div>
                  <span class="operator-name"
                    >操作员：{{ this.userInfo.realname }}</span
                  >
                  <span class="time">{{ formatTime }}</span>
                </div>
                <img
                  v-if="isOnline"
                  alt="网络已连接"
                  src="https://img.51miz.com/Element/00/88/14/85/09fcb840_E881485_e7565f91.png"
                  class="status-icon"
                />
                <img
                  v-else
                  alt="网络未连接"
                  src="https://pic.616pic.com/ys_bnew_img/00/24/28/a1X7m3RsBi.jpg"
                  class="status-icon"
                />
              </div>
              <div><span class="operator-name">技术支持@云南励文科技发展有限公司</span></div>
            </div>

            <div class="txt-box">
              <span style="margin-right: 10px"
                >总数量：<span class="goods-type-num">{{
                  totalCount
                }}</span></span
              >
              <span
                >总金额：<span class="goods-total-price price">{{
                  totalPrice
                }}</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 修改数量模态框 -->
      <el-dialog
        title="数量与成本调整"
        :visible.sync="manualDialog"
        width="60%"
        :close-on-click-modal="false"
        @close="closeDialog"
      >
      <div style="display: flex;justify-content: center;align-items: stretch">
        <span>商品数量：</span>
        <input class="custominput" v-model.number="gnum" type="number">
      </div>
      <div style="display: flex;justify-content: center;align-items: stretch;margin-top:30px">
        <span>成本单价：</span>
        <input class="custominput" v-model.number="gprice" type="number">
      </div>
      <el-button @click="editnumDialog" type="primary" style="margin-top: 2%"
          >确定</el-button
        >
        <el-button @click="closeDialog" type="warning" style="margin-top: 2%"
          >关闭</el-button
        >
      </el-dialog>
      <!-- 入库模态框 -->
    <div
      >
    </div>

      <!-- 提交成功模态框 -->
      <div v-if="payResult" class="payment-success">
        <div class="popup">
          <div class="checkmark">✓</div>
          <h2>入库成功</h2>
          <p>您的商品入库已成功完成，谢谢使用。</p>
          <el-button type="primary" @click="close"
            >关闭({{ countdown }}后自动关闭)</el-button
          >
        </div>
      </div>
      <!-- 支付失败模态框 -->
      <div v-if="payFailed" class="payment-failed">
        <div class="popup">
          <div class="checkmark1">✕</div>
          <h2>入库失败</h2>
          <p>入库失败,请稍后再试!</p>
          <el-button type="primary" @click="close"
            >关闭({{ countdown }}后自动关闭)</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import Logout from "@/components/logout/index";
import ManualInput from "@/view/mian/manualInput";
import HandleCashPayment from "@/view/mian/handleCashPayment";
import HandleEPayMent from "@/view/mian/handleEPayMent";
import Pay from "@/view/mian/pay";
import "@/assets/css/base.css";
import "@/assets/css/scan.css";
import "@/assets/css/index.css";
import "@/assets/css/paid.css";

export default {
  components: {
    Logout,
    ManualInput,
    HandleCashPayment,
    HandleEPayMent,
    Pay,
  },
  data() {
    return {
      netWorkStatus: null,
      now: new Date(),
      payFailed: false,
      loading: false,
      countdown: 3,
      countdownInterval: null,
      payResult: false,
      billNo: "",
      batchNo: "",
      warehouseAndInvoiceInfo: {},
      manualDialog: false,
      radio: "",
      showPay: false,
      recePay: false,
      handleCashPayment: false,
      handleEPayment: false,
      inputMsg: "请使用扫码设备扫码",
      goods: [],
      goodListTable: [],
      showPayType: true,
      goodsCode: "",
      totalCount: 0,
      totalPrice: 0,
      screenWidth: 0,
      screenHeight: 0,
      gindex:0,
      gnum:0,
      gprice:0
    };
  },
  watch: {
    isOnline(newStatus) {
      this.netWorkStatus = newStatus;
    },
  },
  computed: {
    isOnline() {
      return this.netWorkStatus != null ? this.netWorkStatus : navigator.onLine;
    },
    formatTime() {
      let hours = this.now.getHours();
      let minutes = this.now.getMinutes();
      let seconds = this.now.getSeconds();
      let year = this.now.getFullYear();
      let month = this.now.getMonth() + 1;
      let day = this.now.getDate();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    ...mapState({
      shopInfo: (state) => state.shopInfo,
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    setTimeout(() => {
      this.autoFunction();
    }, 1000);
  },
  methods: {
    goodsReceiving() {
      this.$router.push("/receiving");
    },
    goodsHome() {
      this.$router.push("/Index");
    },
    // 清空登录信息
    ...mapMutations(["clearUserInfo"]),
    manualInput() {
      this.manualDialog = true;
      this.removeFocus();
    },
    updateWindowDimensions() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight + 20;
    },
    showPayResult(result) {
      this.recePay = false;

      if (result) {
        this.payResult = true;
      } else {
        console.log(result);
        this.payFailed = true;
      }
      this.startCountdown();
    },
    startCountdown() {
      this.countdown = 3;
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.close();
        }
      }, 1000);
    },
    close() {
      if (this.payResult) {
        this.payResult = false;
        this.$router.push("/Index")
      }
      if (this.payFailed) {
        this.payFailed = false;
      }
      clearInterval(this.countdownInterval);
    },

    removeFocus() {
      if (this.$refs.inputRef) {
        // console.log("放弃焦点");
        this.$refs.inputRef.blur();
      }
    },
    handleClose(type) {
      this.$nextTick(() => {
        if (this.$refs.inputRef) {
          this.$refs.inputRef.focus();
        }
      });
    },
    handleChangeePay(e) {
      if (e == "1") {
        this.showPayType = true;
      } else {
        this.showPayType = false;
      }
    },
    autoFunction() {
      if (this.$refs.inputRef) {
        //console.log('自动聚焦')
        this.$refs.inputRef.focus();
      }
    },
    // 输入框失去焦点
    blur() {
      //判断不是打开拟态框的情况
      if (this.recePay||this.manualDialog) {
      } else {
        this.autoFunction();
      }
    },
    // matchGoods(code) {
    //   code = code.trim();
    //   let regex = /^\d{13}(\d{5})?$/;
    //   if (regex.test(code)) {
    //     this.goodsCode = code;
    //     return true;
    //   }
    //   return false;
    // },
    // 退出登录
    logout(id) {
      //弹出确定退出确认框
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$http
            .get(`/shopUser/setRecentShop?shopId=${id}&type=set`)
            .then((res) => {
              if (res.success) {
                this.$http.get(`/sys/logout`);
                this.clearUserInfo();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
          this.$refs.inputRef.focus();
        });
    },
    // 手动获取商品信息
    queryGoodsById(code, type) {
      // this.goodsCode = code;
      console.log("调用6666", code);
      if (code.length === 0 && type) {
        this.$message.warning("请输入商品码");
        return;
      }
      if (!type) {
        return (this.manualDialog = type);
      }
      if (type) {
        this.$http
          .get("/base/basMaterial/queryByCode?code=" + code)
          .then((response) => {
            if (response.result == null) {
              this.$message.error(response.message);
              this.goodsCode = "";
            } else {
              this.goodListTable = response.result;
              console.log("打印", this.goodListTable);
            }
          })
          .catch((error) => {
            //请求异常
            this.$message.error(error);
          });
      }
    },
    async getBillNo() {
      try {
        const response = await this.$http.put(
          // `/sys/fillRule/executeRuleByCode/stk_cgrk_bill_no`,
          `sys/fillRule/executeRuleByCode/stk_qtrk_bill_no`,
          {}
        );
        if (response.success) {
          return response.result;
        } else {
          throw new Error(response.message || "Failed to get bill number");
        }
      } catch (error) {
        console.error("Error fetching bill number:", error);
        throw error;
      }
    },

    async submitreceiving() {
      try {
        if (this.totalPrice == 0) {
          this.$message.warning("请先添加商品");
          return;
        }
        this.recePay = true;
        const billNo = await this.getBillNo();
        const orderInfo = {
          billNo: billNo,
          billDate: this.getCurrentDateFormatted(),
          // stockIoType: "101", //采购入库, //出入库类型：：注释掉的内容为采购入库
          // invoiceType: "0", //发票类型,普通发票
          // hasRp: "1",
          stockIoType: "199", //其他入库, //出入库类型
          hasRp: "0",
          hasSwell: "0",
          isAuto: "0",
          isRubric: "0",
          // opDept: "",
          operator: this.userInfo.realname, //业务员
          srcBillId: "",
          srcBillType: "",
          srcNo: "",
          // supplierId: "1848187941055295489", //供应商id
          stkIoEntryList: await this.getGoodsInfos(billNo), //入库商品
        };
        this.loading = true;
        const submitBill = await this.$http.post(
          "/stock/stkIo/add/submit",
          orderInfo
        );
        if (submitBill.success) {
          this.loading = false;
          this.showPay = false;
          this.showPayResult(true); //入库完成
          this.clearGoods();
        } else {
          this.loading = false;
          this.showPayResult(false); //入库失败
          this.$message.error(submitBill.message);
        }
      } catch (error) {
        this.loading = false;
        this.showPayResult(false);
        // this.$message.error("提交失败: " + error.message);
        // console.error('Error submitting order:', error);
      }
    },

    async getGoodsInfos(billNo) {
      const goodsInfos = [];
      console.log(billNo);
      for (let index = 0; index < this.goods.length; index++) {
        const model =
          this.goods[index].model == null ? "" : this.goods[index].model;
        let entry = (index + 1) * 10;
        let batch = billNo + entry;
        goodsInfos.push({
          entryNo: entry,
          materialId: this.goods[index].id,
          // materialModel: model,：：注释掉的内容均为采购入库
          materialModel: '',
          stockIoDirection: "1",
          warehouseId: this.shopInfo.id,
          batchNo: batch,
          unitId: this.goods[index].unitId, //单位
          // inventoryQty: 0,
          // inventoryCost: 0,
          qty: this.goods[index].num,
          cost: this.goods[index].salePrice * this.goods[index].num,
          // swellQty: 0,
          // settleQty: this.goods[index].num, //结算数量
          // taxRate: "0", //税率
          price: this.goods[index].salePrice, //含税单价
          // discountRate: 100, //
          // expense: 0, //采购费用
          // tax: 0,
          // settleAmt: this.goods[index].salePrice * this.goods[index].num,
          // srcNo: "",
          remark: "",
          custom1: "",
          custom2: "",
        });
      }
      console.log("yy", goodsInfos);
      return goodsInfos;
    },

    // 扫码输入
    showGoods(event) {
      console.log("走扫码");
      var num = String.fromCharCode(event.keyCode);
      this.goodsCode += num;
      if (this.goodsCode.length == 0) {
        return this.$message.error("商品码不能为空");
      }
      if (event.keyCode == 13) {
        this.$http
          .get("/base/basMaterial/queryByCode?code=" + this.goodsCode)
          .then((response) => {
            if (response.result == null) {
              this.$message.error(response.message);
              this.goodsCode = "";
            } else {
              var tt = -1;
              for (let i = 0; i < this.goods.length; i++) {
                if (this.goods[i].code === response.result.code) {
                  this.goods[i].num++;
                  this.goodsCode = "";
                  tt = 1;
                  break;
                }
              }
              //tt作为一个信号量当tt不等于-1时表示商品已经存在，只需要添加数量
              if (tt === -1) {
                response.result.num = 1;
                var goods = response.result;
                var tem = goods.thumb;
               // goods.thumb = process.env.VUE_APP_IMG_URL + tem;
                this.goods.unshift(goods);
                this.goodsCode = "";
                this.inputMsg = "请使用扫码设备扫码";
              }
            }
          })
          .catch((error) => {
            //请求异常
            this.$message.error(error);
          });
      }
    },
    // 减少商品
    reduce(index) {
      if (this.goods[index].num <= 1) {
        this.goods.splice(index, 1);
      } else {
        this.goods[index].num--;
      }
    },
    // 增加商品
    addition(index) {
      this.goods[index].num++;
    },
    // 触发修改数量和成本单价
    editnum(index,num,price){
      this.manualDialog = true;
      this.gnum=num,
      this.gprice=price,
      this.gindex=index
    },
    //修改数量和成本单价
    editnumDialog(){
      let gindex=this.gindex;
      let gnum=this.gnum;
      let gprice=this.gprice;
      this.manualDialog = false;
      this.goods[gindex].num=gnum;
      this.goods[gindex].salePrice=gprice;
    },
    closeDialog() {
      this.manualDialog = false;
    },
    // 删除商品
    clearGoods() {
      this.goods = [];
      this.totalPrice = 0;
      this.totalCount = 0;
    },
    //向支付页面发送上商品详情
    sendGoodsTake() {
      var goodsData = {
        goods: this.goods,
        totalPrice: this.totalPrice,
        totalCount: this.totalCount,
      };
      return goodsData;
    },
    // 获取当前时间
    getCurrentDateFormatted() {

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    setInterval(() => {
      this.now = new Date();
    }, 1000); // 更新时间每秒
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  },
  // 更新前需要进行的操作
  beforeUpdate() {
    var sum = 0;
    var sum1 = 0;
    for (let i = 0; i < this.goods.length; i++) {
      sum += this.goods[i].salePrice * this.goods[i].num;
      sum1 += this.goods[i].num;
    }
    this.totalPrice = sum.toFixed(2);
    this.totalCount = sum1;
  },
  updated() {
    if (this.manualDialog) {
      this.removeFocus;
    } else {
      this.autoFunction();
    }
  },
};
</script>
<!--<script setup>-->
<!--import main from "../../components/main/index";-->
<!--</script>-->
<style scoped>
.main_box {
  /* background: #0d015a; */
  width: 100vw;
  /* height: 90vh; */
  overflow: hidden;
}
.shouyin-header2 {
  height: 80px;
  background: #056d87;
  color: #fff;
  line-height: 80px;
  padding: 0 var(--padding);
  z-index: -1;
  display: flex;
  justify-content: space-between;
}

.other-list {
  display: flex;
  flex-direction: column;
}

.other-list-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.operator-name,
.time,
.status-icon {
  margin-right: 10px; /* 设置每个元素之间的间距 */
}

.status-icon {
  width: 20px;
  height: 20px;
}

.payment-success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
}

.payment-failed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
}

.popup {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: scaleIn 0.5s forwards;
}

.checkmark {
  font-size: 50px;
  color: #4caf50;
  animation: checkmark 0.5s ease-in-out;
}

.checkmark1 {
  font-size: 50px;
  color: #f90808;
  animation: checkmark1 0.5s ease-in-out;
}
.custominput{
  width: 30%;
  text-align: center;
  height: 30px;
  border: 1px solid #5a5e66;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes checkmark {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
</style>
