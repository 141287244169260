import store from '@/store/index.js';

// 生产环境
//const liwen_url = 'https://liwenbao.liwentech.com/liwen/jeecg-boot'				//本地服务器注释这一行

// 本地服务器（打开下面两行）
 const liwen_url = 'http://localhost:8080/jeecg-boot'
 const finalUrl = 'http://localhost:8888/jeecg-boot'

function URL() {
	let groupUrl = ""
	if (store) {
		groupUrl = store.state.groupUrl ? store.state.groupUrl : store.getters.groupUrl;
	}
	let BASE_URL = ''
	//let finalUrl = groupUrl + '/jeecg-boot'			//本地服务器注释这一行
	BASE_URL = !groupUrl ? liwen_url : finalUrl
	return BASE_URL
}

function staticURL() {
	let url = URL()
	return url + '/sys/common/static'
}
const configService = {
	getApiUrl: URL,
	apiUrl: URL,
	lwUrl: liwen_url,
	staticDomainURL: URL,
	staticUrl: staticURL,
	liwenStaticUrl: liwen_url + '/sys/common/static'
};
export default configService
