<template>
  <div>
  <div class="category-sidebar">

<!--    <el-breadcrumb separator-class="el-icon-arrow-right"  separator="/">-->
<!--      <el-breadcrumb-item v-for="category in categories" :key="category.id" @click="handoffType(category)">-->
<!--        {{ category.name }}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
    <el-tabs v-model="name" @tab-click="handoffType">
      <el-tab-pane v-for="category in categories" :key="category.id" :label="category.name" :name="category.name">{{category.name}}</el-tab-pane>
    </el-tabs>
  </div>
    <div class="product-list">
      <div v-for="data in categorieChild" :key="data.id" class="product-item">
        <div>

        <el-image v-if="data.thumb" class="product-image"  :src="staticUrl(data.thumb)" fit="cover"></el-image>
        </div>
        <div  class="product-details">
          <h3  class="product-name">{{ data.name }}</h3>
          <el-button  class="buy-button" type="primary" @click="selectCategory(data)">添加</el-button>
        </div>
        </div>

    </div>
  </div>
</template>

<script>
import {configService } from "@/common/service/config.service"
import {mapMutations, mapState} from "vuex";
export default {
  data() {
    return {
      categories: [

      ],
      categorieChild:[],
      name:''
    };
  },
  computed:{
    ...mapState({
      shopInfo: (state) => state.shopInfo,
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.findData()
  },
  methods: {
    staticUrl(url){
      console.log(url)
      console.log(process.env.VUE_APP_IMG_URL + url)
      return process.env.VUE_APP_IMG_URL + url
    },
    handoffType(data){
      console.log(data._props.name)
      let newname = data._props.name
      // if(this.name==newname){
      //   return
      // }
      for (let i = 0; i < this.categories.length; i++) {
        console.log(i)
       if(this.categories[i].name ==newname){
         console.log(this.categories[i])
         this.categorieChild=this.categories[i].list
       }
      }
      // this.categorieChild=data.list
    },
    findData(){
      this.$http
          .get(`/base/basMaterial/queryByMaterialIdByType`).then(res=>{
            console.log("========",res)
        if(res.code==200){
          this.categories=res.result
          if (this.categories.length>0){
            this.name =  this.categories[0].name
            this.categorieChild = this.categories[0].list
          }

        }

      })
    },
    selectCategory(category) {
      this.$emit('selectCategory', category);
    },
  },
};
</script>

<style scoped>
.category-sidebar {
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  //justify-content: center;
  padding: 16px;
  overflow: auto;
  height: calc(100vh - 180px);
}

.product-item {
  width: 150px;
  height: 270px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 150px;
  height: 170px;
  object-fit: cover;
}

.product-details {
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.product-name {
  margin: 0;
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buy-button {
  //background-color: #a43931;
  width: 100%;
  margin-top: 10px;
}

</style>
