// 导入Vue和VueRouter
import Vue from "vue";
import VueRouter from "vue-router";
import { mapState, mapActions, mapMutations } from "vuex";

import Main from "@/components/main";
import Login from "@/components/login";
import Index from "@/view/mian/index";
import Receiving from "@/view/mian/goodsreceiving";
//import Print from '@/view/print/print.vue';

// 使用VueRouter插件
Vue.use(VueRouter);

// 定义路由配置
const routes = [
  {
    path: "/Index",
    name: "Index",
    component: Index,
  },
  {
    path: "/main",
    name: "main",
    component: Main,
  },
  {
    path: "/",
    redirect: "/login", // 将根路径重定向到登录页面
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/receiving",
    name: "receiving",
    component: Receiving,
  },
  //   {
  //     path: '/print',
  //     name: 'print',
  //     component: Print
  // },
];

// 创建路由器实例
const router = new VueRouter({
  mode: "history", // 使用history模式，去除URL中的#
  routes, // （缩写）相当于 routes: routes
});

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token');

//   if (token) {

//     if (to.path === '/login') {
//       next({ path: '/main' });
//     } else {
//       next();
//     }
//   } else {
//     if (to.path === '/login') {
//       next();
//     } else {
//       next({path: '/login' });}
//   }
// });

// 导出路由器
export default router;
