<template>
  <div class="body">
    <div class="quxue-shouyin">
      <div class="shouyin-header" style="display: flex; justify-content: space-between;">
        <h1>昆明树木园收银系统</h1>
        <Logout @logout="logout" @clearGoods="clearGoods"></Logout>
      </div>
      <div class="shouyin-main" id="app">
        <!-- 左侧扫码部分 -->
        <div class="scan-area">
          <div class="scan-form">
            <input @blur="blur" type="text" class="input-box" readonly autofocus v-model="goodsCode"
              :placeholder="inputMsg" @keydown="showGoods" ref="inputRef" />
            <input type="button" class="btn-box" @click="manualInput" value="手动输入" />
          </div>
          <div class="scan-list">
            <div class="scan-list-content">
              <table cellspacing="0">
                <thead>
                  <tr>
                    <th>图片</th>
                    <th>品名</th>
                    <th>数量</th>
                    <th>单价</th>
                    <th>小计</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(good, index) in goods" :key="index">
                    <td>
                      <el-image style="width: 100px; height: 100px" :src="good.thumb" fit="fit"></el-image>
                    </td>
                    <td>{{ good.name }}</td>
                    <td>
                      <button @click="reduce(index)">-</button>
                      {{ good.num }}
                      <button @click="addition(index)">+</button>
                    </td>
                    <td>{{ good.counterPrice }}</td>
                    <th>{{ good.counterPrice * good.num }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="scan-list-action">
              <div class="btn-box">
                <input type="button" value="清空商品列表" @click="clearGoods" />
                <input type="button" value="结算" @click="gotoPay" />
              </div>
              <div class="other-list">
                <div class="other-list-time">
                  <div>
                    <span class="operator-name">操作员：{{ this.userInfo.realname }}</span>
                    <span class="time">{{ formatTime }}</span>
                  </div>
                  <img v-if="isOnline" alt="网络已连接" src="https://img.51miz.com/Element/00/88/14/85/09fcb840_E881485_e7565f91.png" class="status-icon"/>
                  <img v-else alt="网络未连接" src="https://pic.616pic.com/ys_bnew_img/00/24/28/a1X7m3RsBi.jpg" class="status-icon"/>
                </div>
              </div>

              <div class="txt-box">
                <span style="margin-right: 10px;">数量：<span class="goods-type-num">{{ totalCount }}</span></span>
                <span>总金额：<span class="goods-total-price price">{{ totalPrice }}</span></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 手动输入商品 -->
        <el-dialog title="手动添加商品" :visible.sync="manualDialog" width="30%" :showClose="false">
          <ManualInput @handleEvent="queryGoodsById"> </ManualInput>
        </el-dialog>
        <!-- 支付模态框 -->
        <el-dialog :visible.sync="checkPay" width="55%" :showClose="false" @open="openDialoged" v-loading="loading"
          element-loading-text="正在支付中。。。。" element-loading-background="rgba(0, 0, 0, 0.8)"
          :close-on-click-modal="false">
          <Pay :goodsData="sendGoodsTake" @submit="submit" v-if="showPay"></Pay>
          <div slot="footer" class="dialog-footer" v-if="showPay">
            <el-button @click="noShowPay">取 消</el-button>
          </div>
        </el-dialog>
        <!-- 支付成功模态框 -->
        <div v-if="payResult" class="payment-success">
          <div class="popup">
            <div class="checkmark">✓</div>
            <h2>支付成功</h2>
            <p>您的支付已成功完成，谢谢使用。</p>
            <el-button type="primary" @click="close">关闭({{ countdown }}后自动关闭)</el-button>
          </div>
        </div>
        <!-- 支付失败模态框 -->
        <div v-if="payFailed" class="payment-failed">
          <div class="popup">
            <div class="checkmark1">✕</div>
            <h2>支付失败</h2>
            <p>支付失败,请稍后再试!</p>
            <el-button type="primary" @click="close">关闭({{ countdown }}后自动关闭)</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Logout from "@/components/logout/index";
import ManualInput from "@/view/mian/manualInput";
import HandleCashPayment from "@/view/mian/handleCashPayment";
import HandleEPayMent from "@/view/mian/handleEPayMent";
import Pay from "@/view/mian/pay";
import "@/assets/css/base.css";
import "@/assets/css/scan.css";
import "@/assets/css/index.css";
import "@/assets/css/paid.css";



export default {
  components: {
    Logout,
    ManualInput,
    HandleCashPayment,
    HandleEPayMent,
    Pay
  },
  data() {
    return {
    netWorkStatus: null,
      now: new Date(),
      payFailed: false,
      loading: false,
      countdown: 3,
      countdownInterval: null,
      payResult: false,
      billNo: '',
      batchNo: '',
      warehouseAndInvoiceInfo: {},
      manualDialog: false,
      radio: '',
      showPay: false,
      checkPay: false,
      handleCashPayment: false,
      handleEPayment: false,
      inputMsg: '请使用扫码设备扫码',
      goods: [],
      showPayType: true,
      goodsCode: '',
      totalCount: 0,
      totalPrice: 0,
    };

  },
  watch: {
  isOnline(newStatus){
    this.netWorkStatus = newStatus;
  }
  },
  computed: {
    isOnline() {
      return this.netWorkStatus!=null?this.netWorkStatus:navigator.onLine;
    },
    formatTime() {
      let hours = this.now.getHours();
      let minutes = this.now.getMinutes();
      let seconds = this.now.getSeconds();
      let year = this.now.getFullYear();
      let month = this.now.getMonth() + 1;
      let day = this.now.getDate();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    ...mapState({
      shopInfo: state => state.shopInfo,
      userInfo: state => state.userInfo
    }),
  },
  created() {
    setTimeout(() => {
      this.autoFunction()
    }, 1000)
  },
  methods: {
    // 清空登录信息
    ...mapMutations(["clearUserInfo"]),
    manualInput() {
      this.manualDialog = true
      this.removeFocus()
    },
    showPayResult(result) {
      this.checkPay = false;

      if (result) {
        this.payResult = true;
      } else {
        console.log(result)
        this.payFailed = true;
      }
      this.startCountdown();
    },
    startCountdown() {
      this.countdown = 3;
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.close();
        }
      }, 1000);
    },
    close() {
      if (this.payResult) {
        this.payResult = false;
      }
      if (this.payFailed) {
        this.payFailed = false;
      }
      clearInterval(this.countdownInterval);
    },
    openDialoged() {
      this.showPay = true
    },
    noShowPay() {
      //需要销毁支付组件页面，保证下次再次打开时候数据是最新的
      this.showPay = false,
        this.checkPay = false,
        this.autoFunction()
    },
    gotoPay() {
      if (this.totalPrice == 0) {
        this.$message.warning('请先添加商品')
        return
      }
      this.checkPay = true
      this.removeFocus()
    },
    removeFocus() {
      if (this.$refs.inputRef) {
        // console.log("放弃焦点");
        this.$refs.inputRef.blur();
      }
    },
    handleDialogClose() {
      console.log('关闭')
    },
    handleClose(type) {
      this.$nextTick(() => {
        if (this.$refs.inputRef) {
          this.$refs.inputRef.focus();
        }
      });
    },
    handleChangeePay(e) {
      if (e == '1') {
        this.showPayType = true
      } else {
        this.showPayType = false
      }
    },
    autoFunction() {
      if (this.$refs.inputRef) {
        //console.log('自动聚焦')
        this.$refs.inputRef.focus()
      }

    },
    // 输入框失去焦点
    blur() {
      //判断不是打开拟态框的情况
      if (this.checkPay || this.manualDialog) {
      } else {
        this.autoFunction()
      }
    },
    matchGoods(code) {
      code = code.trim();
      let regex = /^\d{13}(\d{5})?$/
      if (regex.test(code)) {
        this.goodsCode = code
        return true;
      }
      return false;
    },
    // 退出登录
    logout(id) {
      //弹出确定退出确认框
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$http.get(`/shopUser/setRecentShop?shopId=${id}&type=set`).then(res => {
            if (res.success) {
              this.$http.get(`/sys/logout`)
              this.clearUserInfo();
            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出"
          })
          this.$refs.inputRef.focus()
        })
    },
    // 扫码获取商品信息
    queryGoodsById(code, type) {
      // this.goodsCode = code;
      if (code.length === 0 && type) {
        this.$message.warning("请输入商品码");
        return;
      }
      if (!type) {
        return this.manualDialog = type
      }
      const result = this.matchGoods(code);
      if (!result) {
        return this.$message.warning('请输入正确的商品码');
      }
      if (type) {
        this.$http.get("/base/basMaterial/queryByMaterialId?id=" + this.goodsCode).then((response) => {
          if (response.result == null) {
            this.$message.error(response.message);
            this.goodsCode = '';
          } else {
            var tt = -1;
            for (let i = 0; i < this.goods.length; i++) {
              if (this.goods[i].code === response.result.code) {
                this.goods[i].num++;
                this.goodsCode = '';
                tt = 1;
                break;
              }
            }
            //tt作为一个信号量当tt不等于-1时表示商品已经存在，只需要添加数量
            if (tt === -1) {
              var goods = response.result;
              var tem = goods.thumb;
               // goods.thumb = process.env.VUE_APP_IMG_URL + tem;
              this.goods.unshift(goods);
              this.goodsCode = '';
              this.inputMsg = '请使用扫码设备扫码';
            }
          }
        }).catch((error) => {
          //请求异常
          this.$message.error(error);
        });
      }
      // this.manualDialog = type
    },
    // 打使用print.js打印小票
    // printPayInfo() {
    //   var payInfo = {
    //     billNo: this.billNo,
    //     batchNo: this.batchNo,
    //     shopName: this.shopInfo.name,
    //     goods: this.goods,
    //     totalCount: this.totalCount,
    //     totalPrice: this.totalPrice
    //   }
    // },
    async getBillNo() {
      try {
        const response = await this.$http.put(`/sys/fillRule/executeRuleByCode/stk_xsck_bill_no`, {});
        if (response.success) {
          return response.result;
        } else {
          throw new Error(response.message || 'Failed to get bill number');
        }
      } catch (error) {
        console.error('Error fetching bill number:', error);
        throw error;
      }
    },

    async submit(payInfo) {
      console.log(payInfo)
      let url =""
      if (payInfo.payType=="swept"){
        url ="/mall/order/scanCodePlaceOrder?discount="+payInfo.discount+"&type=swept&authCode="+payInfo.authCode
      }else{
        url ="/mall/order/scanCodePlaceOrder?discount="+payInfo.discount+"&type=cash"
      }

      try {
        const billNo = await this.getBillNo();
        const orderInfo = {
          billNo: billNo,
          billDate: this.getCurrentDateFormatted(),
          stockIoType: "201",//出入库类型
          customerId: "1760860143514984449",//客户id,使用默认客户
          invoiceType: "11",//发票类型,普通发票
          stkIoEntryList: await this.getGoodsInfos(),
          payType: payInfo.payType
        };
        if (payInfo.zhaolingMoney) {
          orderInfo.changeMoney = payInfo.zhaolingMoney
          orderInfo.payMoney = payInfo.payMoney
        }
        this.loading = true
        const submitBill = await this.$http.post(url, orderInfo);
        console.log(submitBill)
        if (submitBill.success) {
          this.loading = false
          // this.print(orderInfo, this.goods)
          this.showPay = false;
          this.showPayResult(true) //支付完成
          this.clearGoods();

        } else {
          this.loading = false
          this.showPayResult(false) //支付失败
          this.$message.error(submitBill.message);
        }
      } catch (error) {
        this.loading = false
        this.showPayResult(false)
        // this.$message.error("提交失败: " + error.message);
        // console.error('Error submitting order:', error);
      }
    },
    // print(orderInfo, goodsInfos) {
    //   const goodsList = [];
    //   for (let index = 0; index < goodsInfos.length; index++) {
    //     const element = goodsInfos[index];
    //     goodsList.push({
    //       name: element.name,
    //       price: element.counterPrice,
    //       qty: element.num
    //     })
    //   }
    //   const printPayInfo = {
    //     billNo: orderInfo.billNo,
    //     shopName: this.shopInfo.name,
    //     goods: goodsList,
    //     totalCount: orderInfo.totalCount,
    //     totalPrice: orderInfo.totalPrice,
    //     payTime: orderInfo.billDate,
    //     payType: orderInfo.payType,
    //     payMoney: orderInfo.payMoney,
    //     changeMoney: orderInfo.changeMoney,
    //     phone: this.shopInfo.phone,
    //     operatorName: this.userInfo.realname,
    //     address: this.shopInfo.address
    //   }
    //   this.$http.post("feie/print", printPayInfo).then(res => {
    //     console.log(res)
    //   })
    // },
    async getWarehouseAndInvoiceInfo(index) {
      try {
        const response = await this.$http.get(`/stock/stkInventory/getInventory?batchNo=${this.batchNo}&materialId=${this.goods[index].id}&warehouseId=${this.shopInfo.id}`);
        if (response.success) {
          return response.result;
        } else {
          this.$message.error(response.message);
        }
      } catch (error) {
        this.$message.error('未知错误');
      }
    },

    async getGoodsInfos() {
      const goodsInfos = [];
      for (let index = 0; index < this.goods.length; index++) {
        const warehouseAndInvoiceInfo = await this.getWarehouseAndInvoiceInfo(index);
        const model = this.goods[index].model == null ? '' : this.goods[index].model;
        goodsInfos.push({
          entryNo: index + 1,
          materialId: this.goods[index].id,
          materialModel: model,
          stockIoDirection: '2',
          warehouseId: this.shopInfo.id,
          batchNo: warehouseAndInvoiceInfo.batchNo,
          inventoryUnitId: warehouseAndInvoiceInfo.unitId,
          inventoryQty: this.goods[index].num,
          inventoryCost: this.goods[index].counterPrice * this.goods[index].num,
          unitId: warehouseAndInvoiceInfo.unitId,
          qty: this.goods[index].num,
          cost: this.goods[index].counterPrice,
          swellQty: 0,
          settleQty: this.goods[index].num,
          taxRate: '0',
          price: this.goods[index].counterPrice,
          discountRate: 100,
          tax: 0,
          settleAmt: this.goods[index].counterPrice * this.goods[index].num,
          remark: '',
          custom1: '',
          custom2: ''
        });
      }
      return goodsInfos;
    }

    ,
    // 键盘输入
    showGoods(event) {
      var num = String.fromCharCode(event.keyCode);
      this.goodsCode += num;
      if (this.goodsCode.length == 0) {
        return this.$message.error("商品码不能为空");
      }
      if (event.keyCode == 13) {
        this.$http.get("/base/basMaterial/queryByMaterialId?id=" + this.goodsCode).then((response) => {
          if (response.result == null) {
            this.$message.error(response.message);
            this.goodsCode = '';
          } else {
            var tt = -1;
            for (let i = 0; i < this.goods.length; i++) {
              if (this.goods[i].code === response.result.code) {
                this.goods[i].num++;
                this.goodsCode = '';
                tt = 1;
                break;
              }
            }
            //tt作为一个信号量当tt不等于-1时表示商品已经存在，只需要添加数量
            if (tt === -1) {
              var goods = response.result;
              var tem = goods.thumb;
              goods.thumb = "http://110.41.59.198:8080/liwen-erp/sys/common/static/" + tem;
              this.goods.unshift(goods);
              this.goodsCode = '';
              this.inputMsg = '请使用扫码设备扫码';
            }
          }
        }).catch((error) => {
          //请求异常
          this.$message.error(error);
        });
      }
    },
    // 减少商品
    reduce(index) {
      if (this.goods[index].num <= 1) {
        this.goods.splice(index, 1);
      } else {
        this.goods[index].num--;
      }
    },
    // 增加商品
    addition(index) {
      this.goods[index].num++;
    },
    // 删除商品
    clearGoods() {
      this.goods = [];
      this.totalPrice = 0;
      this.totalCount = 0;
    },
    //向支付页面发送上商品详情
    sendGoodsTake() {
      var goodsData = {
        goods: this.goods,
        totalPrice: this.totalPrice,
        totalCount: this.totalCount
      }
      return goodsData
    },
    // 获取当前时间
    getCurrentDateFormatted() {

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }


  },
  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 1000); // 更新时间每秒
  },
  // 更新前需要进行的操作
  beforeUpdate() {
    console.log("===========beforeUpdate")
    var sum = 0;
    var sum1 = 0;
    for (let i = 0; i < this.goods.length; i++) {
      sum += this.goods[i].counterPrice * this.goods[i].num;
      sum1 += this.goods[i].num;
    }
    this.totalPrice = sum;
    this.totalCount = sum1;
  },
  updated() {
    if (this.checkPay || this.manualDialog) {
      this.removeFocus
    } else {
      this.autoFunction()
    }

  }
};
</script>
<style scoped>
.other-list {
  display: flex;
  flex-direction: column;
}

.other-list-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.operator-name, .time, .status-icon {
  margin-right: 10px; /* 设置每个元素之间的间距 */
}

.status-icon {
  width: 20px;
  height: 20px;
}

.payment-success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
}

.payment-failed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s forwards;
}

.popup {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: scaleIn 0.5s forwards;
}

.checkmark {
  font-size: 50px;
  color: #4CAF50;
  animation: checkmark 0.5s ease-in-out;
}

.checkmark1 {
  font-size: 50px;
  color: #f90808;
  animation: checkmark1 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

@keyframes checkmark {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
</style>
